/* General Form Behavior */

// Prevent double form submissions by disabling form submit elements on submit
jQuery.fn.preventDoubleSubmission = function () {
    $(this).on('submit', function (e) {
        var $form = $(this);
        var excluded = ['extend-session', 'login-modal', 'verify-modal', 'add-new-user-form'];
        if (excluded.includes($form.attr('id'))) return;

        if ($form.data('submitted') === true) {
            e.preventDefault();
        } else {
            $form.data('submitted', true);
            $form.find('button[type=submit]').prop("disabled", true);
            if (!$form.valid()) {
                $form.find('button[type=submit]').prop("disabled", false);
                $form.data('submitted', false);
            }
        }
    });
    return this;
};

$('form').preventDoubleSubmission();

//Re-enable on refresh/back because firefox is dumb and saves this state
$(function () { $('button[type=submit]').attr('disabled', false); });

// Add name/value to form for submit elements that also have a name/value
$(document).on('click keypress', 'form input[type=submit], form button[type=submit]', function (ev) {
    if (ev.which === 13 || ev.which === 32 || ev.type === 'click') {
        var name = $(this).attr('name');
        if (typeof name == 'undefined') return;
        var value = $(this).attr('value');
        var $form = $(this).parents('form').first();
        var $input = $('<input type="hidden" class="temp-hidden" />').attr('name', name).attr('value', value);
        $form.find('input.temp-hidden').remove();
        $form.append($input);
    };
});
