var clipboardButtons = document.querySelectorAll("[data-clipboard]");

async function copyPageUrl(text) {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
}


if (clipboardButtons.length > 0) {
    clipboardButtons.forEach(button => {
        let text = button.dataset.clipboardText;
        button.addEventListener("click", async () => copyPageUrl(text));
    });
}
