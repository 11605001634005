function initializeFileUploadWidgets(content) {

    const widgets = content.querySelectorAll("[data-file-upload-widget]");

    if (widgets.length > 0) {
        import("./file-upload-widget").then(m => {
            widgets.forEach(el => {
                let widget = new m.Widget(el);
            });
        });
    }
}

window.addEventListener("htmx:load", e => initializeFileUploadWidgets(e.target));

