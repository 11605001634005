function attachColorPicker(id) {
    id = `#${id}` 
    var elements = document.querySelectorAll(id);
    elements.forEach(ele => {
        ele.addEventListener("input", watchColorPicker, true);
        ele.addEventListener("change", watchColorPicker, true);
    })
    function watchColorPicker(event) {
        document.querySelectorAll(id).forEach((ele) => {
            ele.value = event.target.value
        })
    }
}

function attachColorPickers() {
    document.querySelectorAll('input[type=color]').forEach(
        ele => {
            attachColorPicker(ele.id)
        });
}

attachColorPickers();