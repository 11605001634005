const editors = document.getElementsByClassName("select-users");

if (editors.length > 0) {
    import("select2").then(select2 => {
        for (var i = 0, len = editors.length | 0; i < len; i = i + 1 | 0) {
            $(editors[i]).select2({
                placeholder: "Search for users by name or email",
                ajax: {
                    dataType: 'json',
                    data: function(params) {
                        var query = {
                            query: params.term
                        }
                        return query;
                    },
                    processResults: function(response) {
                        var data = $.map(response,
                            function(obj) {
                                obj.id = obj.Id || obj.id;
                                obj.text = obj.Text || obj.text;
                                return obj;
                            });
                        return {
                            results: data
                        };
                    },
                    tags: true,
                    tokenSeparators: [',', ' ']
                }
            });
        }
    });
}

