import * as moment from "moment";
import * as jstz from "jstimezonedetect";
import "eonasdan-bootstrap-datetimepicker";

function setInput(dp, format) {
    var utcString = dp.find("input[type=text]").attr("data-utc");
    if (utcString) {
        var dpo = dp.data("DateTimePicker");
        var date = moment(utcString, format);
        dpo.date(date);
    }
}

function setHiddenValue(target, value) {
    let input = $(target).find('input[type=text]');
    let id = input.attr("id");
    id = "#" + id.replace("_input","");
    let form = input.closest("form");
    let hidden = form.find(id);
    $(hidden).val(value);
}

export function initializeInputs() {
    $(".datepicker").each(function() {
        var dp = $(this);
        dp.datetimepicker({ format: "l", allowInputToggle: true, useCurrent: false })
            .on("dp.change",
                function(e) {
                    var v = e.date ? e.date.format("YYYY-MM-DD") : null;
                    setHiddenValue(e.target, v);
                }
            );
        setInput(dp, null);
    });

    $(".today").on("click",
        e => {
            e.preventDefault();
            var btn = $(e.target);
            var dp = btn.parents(".datepicker");
            var dpo = dp.data("DateTimePicker");
            dpo.date(moment().startOf("day"));
        });

    $(".clear-datepicker").on("click", e => {
        e.preventDefault();
        let btn = $(e.target);
        let dp = btn.parents(".datepicker");
        let dpo = dp.data("DateTimePicker");
        dpo.clear();
    });

    $(".timepicker").each(function() {
        var dp = $(this);
        dp.datetimepicker({ format: "LT", stepping: 15, allowInputToggle: true, useCurrent: false })
            .on("dp.change",
                function(e) {
                    var v = e.date.format("HH:mm");
                    setHiddenValue(e.target, v);
                }
            );
        setInput(dp, "HH:mm");
    });


    $(".datetimepicker").each(function() {
        var dp = $(this);
        dp.datetimepicker({ format: "l LT", allowInputToggle: true, useCurrent: false })
            .on("dp.change",
                function(e) {
                    var utc = moment(e.date).utc();
                    var v = utc.toISOString();
                    setHiddenValue(e.target, v);
                }
            );
        setInput(dp, null);
    });

    $(".now").on("click",
        e => {
            e.preventDefault();
            var btn = $(e.target);
            var dp = btn.parents(".datetimepicker");
            var dpo = dp.data("DateTimePicker");
            dpo.date(moment());
        });

    $(".clear-datetimepicker").on("click", e => {
        e.preventDefault();
        let btn = $(e.target);
        let dp = btn.parents(".datetimepicker");
        let dpo = dp.data("DateTimePicker");
        dpo.clear();
    });


    $(".timezone").each(function() {
        if ($(this).val() === "") {
            var tz = jstz.determine().name();
            $(this).val(tz);
        }
    });
}


$(() => {
    initializeInputs();
});