import "htmx.org";
import { initializeInputs } from "./datetime";

window.htmx = require('htmx.org');

document.body.addEventListener('htmx:load', function (evt) {
    initializeInputs();
    let allFormEl = $('.form-checkbox, .form-radio');
    if (allFormEl.length) allFormEl.niftyCheck();
});


document.body.addEventListener('htmx:beforeSwap', function (evt) {
    if (evt.detail.xhr.status === 422) {
        // allow 422 responses to swap as we are using this as a signal that
        // a form was submitted with bad data and want to rerender with the
        // errors
        //
        // set isError to false to avoid error logging in console
        evt.detail.shouldSwap = true;
        evt.detail.isError = false;
    }
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
    $("body").css("padding-right", 0);
});

document.addEventListener("htmx:configRequest", (evt) => {
    evt.detail.headers["X-Requested-With"] = "XMLHttpRequest";
});