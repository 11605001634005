
function rowToggle(e) {
    e.preventDefault();
    const el = e.target;
    const studyCheckboxes = $(`input[data-row=${el.name}]`);
    studyCheckboxes.each(function () {
        if (el.classList.contains('row-select')) {
            this.checked = true;
        } else if (el.classList.contains('row-deselect')) {
            this.checked = false;
        }
    });
}

function columnToggle(e) {
    e.preventDefault();
    const el = e.target;
    const claimCheckboxes = $(`input[data-column="${el.name}"]`);
    claimCheckboxes.each(function () {
        if (el.classList.contains('column-select')) {
            this.checked = true;
        } else if (el.classList.contains('column-deselect')) {
            this.checked = false;
        }
    })
}

function allToggle(e) {
    e.preventDefault();
    const el = e.target;
    const allCheckboxes = $(`input[data-table="${el.name}"`);
    allCheckboxes.each(function () {
        if (el.classList.contains('all-select')) {
            this.checked = true;
        } else if (el.classList.contains('all-deselect')) {
            this.checked = false;
        }
    })
}

$('.row-select, .row-deselect').on('click', rowToggle);
$('.column-select, .column-deselect').on('click', columnToggle);
$('.all-select, .all-deselect').on('click', allToggle);