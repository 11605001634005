function init(content) {

    const buttons = content.querySelectorAll("[data-delete-row]");

    buttons.forEach(b => {
        var table = b.closest('table'); 
        b.addEventListener("click", function (e) {
            var row = e.target.closest('tr');
            table.deleteRow(row.rowIndex);
        })
    })
}

window.addEventListener("htmx:load", e => init(e.target));

