function getPartial(url) {
    $.ajax({
        url: url,
        type: 'GET',
        dataType: "html",
        success: function (response) {
            document.getElementById("uploadDetailsPartial").innerHTML = response;
        }
    })
}

function fileProcessStatus(initialStatus, url) {
    var processingStatuses = ["Failed", "Cancelled", "Succeeded"];

    if (!processingStatuses.includes(initialStatus)) {
        var id = setInterval(refresh, 20000);
        function refresh() {
            var status = document.getElementById("fileProcessStatus").innerText;
            if (!processingStatuses.includes(status)) {
                getPartial(url);
            } else {
                clearInterval(id);
            }
        }
    }
}

window.fileProcessStatus = fileProcessStatus;
