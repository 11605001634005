
function toggleDoNotCollectStudyPartner(content) {
    const spCollection = document.getElementById("study-partner-collection-mode");
    let test = content.querySelectorAll('input[name="DoNotCollectStudyPartner"]').forEach(doNotCollect => {
        doNotCollect.addEventListener("change", function (e) {
            spCollection.classList.toggle("hidden", e.target.checked)
        });

    });
}

window.addEventListener("htmx:load", e => toggleDoNotCollectStudyPartner(e.target));
