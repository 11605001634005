export function initializeMarkdownEditors(content) {
    const elements = content.getElementsByClassName("markdown-editor");

    if (elements.length > 0) {
        import("easymde").then(({ default: EasyMDE }) => {
            for (var i = 0, len = elements.length | 0; i < len; i = i + 1 | 0) {
                let element = elements[i];
                if (element.nextElementSibling?.classList.contains("EasyMDEContainer")) { }
                else {
                    const editor = new EasyMDE({
                        autoRefresh: true,
                        forceSync: true,
                        element: element
                    });
                }

            }
        });
    }
}

window.addEventListener("htmx:load", e => initializeMarkdownEditors(e.target));