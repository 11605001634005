function selectAll(content) {
    var $slctAll = $(content).find(".select-all");

    function setAllCheckbox($checkboxes, boolValue) {
        $checkboxes.each(function (idx, el) {el.checked = boolValue;});
    }

    $slctAll.each(function (idx, el) {
        var $el = $(el),
            $checkboxes = $el.find("input[type=checkbox]"),
            $selBtn = $("<a href='#'>Select All</a>"),
            $desBtn = $("<a href='#'>Deselect All</a>"),
            $div = $("<div></div>");

        $selBtn.on("click", function (e) {
            setAllCheckbox($checkboxes, true);
            e.preventDefault();
        });

        $desBtn.on("click", function (e) {
            setAllCheckbox($checkboxes, false);
            e.preventDefault();
        });

        $div.append($selBtn);
        $div.append("|");
        $div.append($desBtn);
        $el.prepend($div);
    })
};

window.addEventListener("htmx:load", e => selectAll(e.target));
