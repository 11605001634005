function specimenForm(content) {
    content.querySelectorAll("[data-specimen-form]").forEach(form => {
        let plasmaReceived = form.querySelectorAll('input[name=PlasmaReceived]');
        let plasmaDetails = form.querySelector(".plasma-details");

        plasmaReceived.forEach(el => {
            el.addEventListener("click", function () {
                plasmaDetails.classList.toggle("hidden", el.value != "True")
            });
        });

        let serumReceived = form.querySelectorAll('input[name=SerumReceived]');
        let serumDetails = form.querySelector(".serum-details");

        serumReceived.forEach(el => {
            el.addEventListener("click", function () {
                serumDetails.classList.toggle("hidden", el.value != "True")
            });
        });

        let wholeBloodReceived = form.querySelectorAll('input[name=WholeBloodReceived]');
        let wholeBloodDetails = form.querySelector(".whole-blood-details");

        wholeBloodReceived.forEach(el => {
            el.addEventListener("click", function () {
                wholeBloodDetails.classList.toggle("hidden", el.value != "True")
            });
        });
    });
};

window.addEventListener("htmx:load", e => specimenForm(e.target));
