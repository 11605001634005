function init(content) {

    const forms = content.querySelectorAll("[data-study-payment-form]");

    if (forms.length > 0) {
        forms.forEach(form => {
            const procedureTypes = $(form).find("div[name=procedureType]");
            const timepoints = $(form).find("div[name=timepoint]");
            function toggle() {
                var isStudyPartner = "";

                var studyPartnerInput = $(form).find("input[name=IsStudyPartner]:checked").val();
                if (studyPartnerInput == null) {
                    isStudyPartner = $(form).find("input[name=IsStudyPartner]").val() ?? "False";             
                } else {
                    isStudyPartner = studyPartnerInput ?? "False";
                }

                for (var i = 0; i < procedureTypes.length; i++) {
                    var procedureType = procedureTypes[i];
                    if (procedureType.dataset.isStudyPartner == isStudyPartner) {
                        procedureType.classList.remove("hidden");
                    } else {
                        procedureType.classList.add("hidden");
                    }
                }

                for (var i = 0; i < timepoints.length; i++) {
                    var tp = timepoints[i];
                    if (tp.dataset.isStudyPartner == isStudyPartner) {
                        tp.classList.remove("hidden");
                    } else {
                        tp.classList.add("hidden");
                    }
                }
            }

            toggle();
            $(form).find("#studyPartnerRadio").on("change", toggle);
        });
    }
}

window.addEventListener("htmx:load", e => init(e.target));