import "bootstrap";

$(function () {
    $('[data-toggle="popover"]').popover();

    $('.table-datatable').on('draw.dt', function () {
        $('[data-toggle="popover"]').popover();
    });

    $('.collapse').on('hidden.bs.collapse shown.bs.collapse',
        function(e) {
            $(e.currentTarget)
                .parents('.panel')
                .children('.panel-heading')
                .find('.fa-chevron-up, .fa-chevron-down')
                .toggleClass(['fa-chevron-up', 'fa-chevron-down']);
        });
});

