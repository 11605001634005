function localize(t) {
    var d = Date.parse(t);
    if (!d)
        return null;
    return new Intl.DateTimeFormat(window.navigator.languages, { dateStyle: 'long', timeStyle: 'long' }).format(d);
};

export function localizeDateTimeElements(content) {
    let els = content.getElementsByClassName("datetime");
    Array.from(els).forEach((el) => {
        if (el.attributes["data-utc"])
            el.textContent = localize(el.attributes["data-utc"].value);
    });
}

window.addEventListener("htmx:load", e => localizeDateTimeElements(e.target));