import { initializeInputs } from "./datetime";

function submit(e) {
    e.preventDefault();
    var $form = $(this);
    $form.validate();
    if ($form.valid()) {
        var spinner = new Spinner().spin($form[0]);
        $.ajax({
            url: this.action,
            type: this.method,
            data: $form.serialize(),
            success: function (result) {
                let $el = null;
                if (result.errors) {
                    $el = $form.html(result.html);
                    
                    if (["login-modal", "verify-modal"].includes($form.attr("id"))) {
                        $(".login-error-msg").text(result.errorMsg);
                    }
                } else if (result.add) {
                    $form.closest(".modal").modal("hide");
                    $el = $('<div class="panel replace" data-id="' + result.id + '" id="display-' + result.id + '">');
                    if (result.addTo) {
                        $(result.addTo).append($el);
                        $el.addClass("panel-primary");
                    } else {
                        $(".add").last().append($el);
                    }
                    $el.html(result.html);
                } else if (result.redirect) {
                    window.location.href = result.url;
                } else if (result.refresh) {
                    window.location.reload();
                } else if (result.verify) {
                    $("#login-container").hide();
                    $("#two-factor-container").show();
                } else if (result.html){
                    $form.closest(".modal").modal("hide");
                    $el = $form.closest(".replace").html(result.html);
                } else {
                    $form.closest(".modal").modal("hide");
                }

                bindForms();
                initializeInputs();

                if ($el !== null) {
                    $el.find(".form-checkbox, .form-radio").niftyCheck();
                }
                spinner.stop();

                var sessionExtenders = ["extend-session", "login-modal", "verify-modal"];
                if (sessionExtenders.includes($form.attr("id")) && !result.errors) {
                    enableLockScreen();
                }
            },
            error: function (result) {
                console.log(result);
                $form.html(result.responseText);
            }
        });
    }
}

function bindForms() {
    $(".ajax-form, .ajax-add").one("submit", submit);
}

$(function () {
    bindForms();
});
