function bloodDrawForm(content) {
    let form = content.querySelector("[data-study-form]");
    if (form) {
        let useBloodDraw = form.querySelectorAll('input[name=UseBloodDraw]');
        let bloodDrawSettings = form.querySelector(".blood-draw-settings");
        useBloodDraw.forEach(el => {
            el.addEventListener("click", function (e) {
                bloodDrawSettings.classList.toggle("hidden");
            });
        });
    }
};

window.addEventListener("load", e => bloodDrawForm(e.target));
