class locationEditor {
    constructor() {
        this.$input = $(".location-input");
        this.$formatted = $(".location.formatted");
        this.$latitude = $(".location.latitude");
        this.$longitude = $(".location.longitude");
        this.$address = $(".location.address");
        this.$editor = $(".location-form");

        //setup map
        this.setupMap({});

        $(function() {
            $('[data-toggle="tooltip"]').tooltip();
        });

        // setup places autocomplete
        this.AutocompleteService = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder();

        // setup events on input and edit boxes
        this.handleInput();
        this.handleEdit();
    }

    // setup map
    setupMap(browserPosition) {
        // Create a "Google(r)(tm)" LatLong object representing our lat/long.
        let position, zoom;

        if (browserPosition.coords) {
            position = new google.maps.LatLng(browserPosition.coords.latitude, browserPosition.coords.longitude);
            zoom = 12;
            this.geocoder.geocode({ "location": position }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.changePlace(results[0], true);
                }
            });
        } else if (this.$latitude.val()) {
            position = new google.maps.LatLng(this.$latitude.val(), this.$longitude.val());
            zoom = 16;
        }

        if (position) {
            this.$latitude.val(position.lat());
            this.$longitude.val(position.lng());
        } else {
            zoom = 4;
            position = new google.maps.LatLng(38, -98);
        }

        // Initialize the map widget.
        this.map = new google.maps.Map($(".location-map")[0], {
            zoom: zoom,
            center: position,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            maxZoom: 14
        });

        // Place a marker on it, representing the DBGeometry object's position.
        this.marker = new google.maps.Marker({
           position: position,
           map: this.map
        });

        // add tooltip
        this.infowindow = new google.maps.InfoWindow();
    }

    // adds events and typeahead to $input
    handleInput() {
        const findLocations = (query) => this.findLocations(query);

        import("select2").then(select2 => {
            this.$input.select2({
                delay: 250,
                minimumInputLength: 2,
                placeholder: this.$formatted[0].value || "Enter Address ... ",
                ajax: {
                    dataType: "json",
                    transport: async function (params, success, failure) {
                        const query = params.data.term;
                        const returnData = await findLocations(query).then(res => {
                            return res
                        });
                        success(returnData);
                    },
                    processResults: function (response) {
                        return {
                            results: response
                        }
                    },
                    tokenSeparators: [",", " "]
                },
            }).on("select2:select",
                (evt, item) => {
                    const selectedAddress = $(".select2-selection__rendered")[0].innerHTML;
                    this.getFirstResult(selectedAddress, true);
                });
        });
    }

    // adds events to editor boxes
    handleEdit() {
        //show editor if there is already a value
        if ($(".has-error").length > 0) {
            this.$editor.removeClass("hidden");
        }

        $(".show").each((i, el) => {
            if ($(el).val()) {
                this.$editor.removeClass("hidden");
            }
        });

        // change map location based on editor
        this.$editor
            .on("keydown", (event) => {
                if (event.keyCode == 13) {
                    event.preventDefault();
                    this.updateMap();
                }
            })
            .on("focusout", () => this.updateMap());
    }

    //provides select2 with results for dropdown
    findLocations(query) {
        let request = {
            input: query,
            types: ["geocode"],
            bounds: this.map.getBounds()
        };
        return this.AutocompleteService.getPlacePredictions(request)
            .then(res => {
                const mappedResults = res.predictions.map(place => {
                    return { id: place.place_id, text: place.description }
                });
                return mappedResults;
            });
    }

    // looks for the first autocomplete selection and chooses it.     
    getFirstResult(searchString, updateForm, repeat) {
        this.AutocompleteService.getPlacePredictions({
            input: searchString,
            types: ["geocode"],
            bounds: this.map.getBounds()
        }, (places, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                const firstResult = places[0].description;
                this.geocoder.geocode({ "address": firstResult }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        this.changePlace(results[0], updateForm);
                    }
                });
            } else if (!repeat) {
                this.getFirstResult($(".location.postal_code").val(), false, true);
            }
        });
    }

    // updates map and forms to show a new location. Accepts Google's PlaceResult     
    changePlace(place, updateForm) {
        this.place = place;

        if (!this.place.geometry) {
            console.log("Autocomplete's returned place contains no geometry");
            return;
        }

        // If the place has a geometry, then present it on a map.
        if (this.place.geometry.viewport) {
            this.map.fitBounds(this.place.geometry.viewport);
        } else {
            this.map.setCenter(this.place.geometry.location);
            this.map.setZoom(17);  // Why 17? Because it looks good.
        }

        this.moveMarker();
        this.fillForm(updateForm);
    }

    // moves the marker
    moveMarker() {
        this.marker.setPosition(this.place.geometry.location);
        this.marker.setVisible(true);
        this.infowindow.setContent(this.place.formatted_address);
        this.infowindow.open(this.map, this.marker);
    }

    // Fills in location form with place data. Looks for inputs with class="location address_component_name"
    // if update is true, changes visible editor boxes, otherwise, only changes invisible ones
    fillForm(update) {
            if (update) {
                $(".location").val("");
            }
            console.log("update", update)
            this.$latitude.val(this.place.geometry.location.lat());
            this.$longitude.val(this.place.geometry.location.lng());
            this.$address.val(JSON.stringify(this.place));
            this.$formatted.val(this.place.formatted_address);

        let street, num, name;
        const parts = this.place.address_components;
            for (let i in parts) {
                const $el = $(".location." + parts[i].types[0]);
                if (parts[i].types[0] === "country") {
                    name = parts[i].short_name;
                } else if (parts[i].types[0] === "street_number") {
                    num = parts[i].long_name;
                } else if (parts[i].types[0] === "route") {
                    street = parts[i].long_name;
                } else {
                    name = parts[i].long_name;
                }

                if ($el.length > 0) {
                    if ($el.hasClass("show")) {
                        if (update) {
                            $el.val(name);
                        }
                    } else {
                        $el.val(name);
                    }
                }
            }

            if (update && (num || street)) {
                $(".line1").val([num, street].join(" "));
            }

            this.$editor.removeClass("hidden");
    }

    // updates the map based on editor
    updateMap() {
        let newAddress = "";
        $(".show").each(function (i, el) {
            if (!$(el).hasClass("line2")) {
                newAddress = newAddress + " " + $(el).val();
            }
        });

        this.$input.val(newAddress);
        this.getFirstResult(newAddress, false);
    }
};

window.locationEditor = locationEditor;
