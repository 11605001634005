// Javascript to enable link to tab
// https://stackoverflow.com/a/21494906/130283

function showTab(id) {
    let targetTab = $("a[href='" +id + "']");
    targetTab.tab("show");
    targetTab.parents('.tab-pane').each(function() {
        $(this).tab("show");
    });
    targetTab.parents('.panel-body').each(function() {
        $(this).collapse("show");
    });
}

$(document).ready(function() {
    if (location.hash) {
        showTab(location.hash);
    }
});

$(window).on("popstate", function() {
    const anchor = location.hash || $("a[data-toggle='tab']").first().attr("href");
    showTab(anchor);
});

$(document.body).on("click", "a[data-toggle='tab']", function() {
    location.hash = this.getAttribute("href");
});

$(document.body).on("click", "a.tab-return-url", function() {
    const href = this.getAttribute("href");
    const base = document.baseURI;
    const url = new URL(href, base);
    const params = new URLSearchParams(url.search);
    params.set('returnURL', location.href);
    url.search = params.toString();
    $(this).attr("href", url.toString());
});

$(document.body).on("click", ".tab-return-url[type=submit]", function() {
    const form = $(this).closest("form");
    $("<input />").attr("type", "hidden")
        .attr("name", "returnURL")
        .attr("value", location.href)
        .appendTo(form);
});