function renderEnrollmentChart(element, series, categories) {
    import("apexcharts").then(({ default: ApexCharts }) => {
        let options = {
            series,
            xaxis: {
                categories,
                labels: {
                    trim: true
                },
                maxHeight: 20
            },
            colors: ["#5fa2dd", "#9cc96b", "#ebaa4b", "#f76c51"],
            chart: {
                type: "bar",
                height: 400
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded"
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"]
            },
            yaxis: {
                title: {
                    text: "# of Subjects"
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: (val) => (`${val} Subject${val != 1 ? "s" : ""}`)
                }
            }
        };

        var chart = new ApexCharts(element, options);
        chart.render();
        return chart;
    });
}

window.renderEnrollmentChart = renderEnrollmentChart;