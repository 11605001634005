function initializeResizer(content) {

    const iframes = content.querySelectorAll("[data-template-iframe]");

    function isVisible(el) {
        return (el.offsetParent !== null)
    }

    function respondToVisibility(element, callback) {
        var options = {
            root: document.documentElement,
        };

        var observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                callback(entry.intersectionRatio > 0);
            });
        }, options);

        observer.observe(element);
    }

    if (iframes.length > 0) {
        import("iframe-resizer").then(m => {

            function injectResizer(iframe) {
                let doc = iframe.contentDocument || iframe.contentWindow.document;
                let script = doc.createElement("script");
                script.src = "/dist/iframeResizer.contentWindow.js";
                doc.body.appendChild(script);

                let tag = doc.createElement("div");
                tag.innerHTML = '&nbsp;';
                tag.dataset.iframeHeight = true;
                tag.style = "max-height: 0;";
                doc.body.appendChild(tag);

                let resizer = m.iframeResizer({
                    checkOrigin: false,
                    heightCalculationMethod: 'taggedElement'
                }, iframe)[0];

                respondToVisibility(iframe, (isVisible) => {
                    if(isVisible) {
                        console.debug(iframe.src)
                        resizer.iFrameResizer.resize();
                    }
                });
            }

            iframes.forEach(iframe => {
                if (iframe.contentDocument.readyState === "complete")
                    injectResizer(iframe);

                iframe.addEventListener('load', () => {
                    console.debug(iframe.src);
                    injectResizer(iframe)
                });
            });
        });
    }

}

window.addEventListener("htmx:load", e => initializeResizer(e.target));
