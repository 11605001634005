function dropdownStudyGroup(content) {
    let dropdown = content.querySelector("#dropdown-study-group")
    const update = () => {
        if (dropdown.value == "create-new-group") {
            $("#new-study-group")[0].className = "show";
        } else {
            $("#new-study-group")[0].className = "hidden";
        }
    }

    if (dropdown) {
        update();
        dropdown.addEventListener("change", update);
    }
};

window.addEventListener("htmx:load", e => dropdownStudyGroup(e.target));