const players = document.querySelectorAll("lottie-player");

if (players.length > 0) {
    import("@lottiefiles/lottie-player");
}

function loadDynamicAnimation(animationSource, title, content) {
    let animData = JSON.parse(animationSource);

    for (var i = 0; i < animData.layers.length; i++) {
        var layer = animData.layers[i];
        if (layer.nm == ".Title") {
            animData.layers[i].t.d.k[0].s.t = title;
        }

        if (layer.nm == ".Content") {
            animData.layers[i].t.d.k[0].s.t = content;
        }
    }

    let newLottie = JSON.stringify(animData);
    return newLottie;
}

function renderLottieAnimation(p, animationSource, title, content) {
    
    p.load(loadDynamicAnimation(animationSource, title, content));
}

window.renderLottieAnimation = renderLottieAnimation;

function initializeLottieEditor() {
    const dropdownSelection = document.getElementById('animation-dropdown');

    function reloadLottieAnimations() {
        let animationSource = dropdownSelection.selectedOptions[0].dataset['animationSource'];

        let useTitle = dropdownSelection.selectedOptions[0].dataset['useTitle'];
        var titleEditors = document.querySelectorAll(".encouragement-title");
        titleEditors.forEach((e) => {
            useTitle === "False"
                ? e.setAttribute('readonly', true)
                : e.removeAttribute('readonly');
        });

        let useContent = dropdownSelection.selectedOptions[0].dataset['useContent'];
        var contentEditors = document.querySelectorAll(".encouragement-content");
        contentEditors.forEach((e) => {
            useContent === "False"
                ? e.setAttribute('readonly', true)
                : e.removeAttribute('readonly');
        });

        players.forEach((p) => {
            let languageCode = p.dataset["languageCode"];
            let title = document.querySelector(`.title-${languageCode}`).value;
            let content = document.querySelector(`.content-${languageCode}`).value;
            renderLottieAnimation(p, animationSource, title, content);
        });
    }

    reloadLottieAnimations();

    dropdownSelection.addEventListener("change", (e) => {
        reloadLottieAnimations();
    });

    var dynamicTextElements = document.querySelectorAll(".encouragement-text");
    dynamicTextElements.forEach((element) => {
        element.addEventListener("keyup", (e) => {
            reloadLottieAnimations();
        });
    });

}

window.initializeLottieEditor = initializeLottieEditor