function paymentEligibilityForm(content) {
    let form = content.querySelector("[data-study-form]");
    if (form) {
        let usePayments = form.querySelectorAll('input[name=UsePayments]');
        let paymentEligibilitySettings = form.querySelector(".payment-eligibility-settings");
        usePayments.forEach(el => {
            el.addEventListener("click", function (e) {
                paymentEligibilitySettings.classList.toggle("hidden");
            });
        });
    }
};

window.addEventListener("load", e => paymentEligibilityForm(e.target));
