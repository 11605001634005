import Sortable from 'sortablejs';

function init(content) {

    let layout = content.id == "portal-layout"
        ? content
        : content.querySelector("#portal-layout");

    if (layout == null) return;

    let sections = content.querySelector("[data-sortable-sections]");

    new Sortable(sections, {
        group: "sections",
        animation: 150,
        handle: ".drag-handle"
    });

    let tasks = content.querySelectorAll("[data-sortable-tasks]");
    for (let i = 0; i < tasks.length; i++) {
        let task = tasks[i];

        if (task.classList.contains("start")) {
            new Sortable(task, {
                group: "start-tasks",
                animation: 150,
                filter: '.add-task',
                handle: ".drag-handle"
            });
        }
        else {
            new Sortable(task, {
                group: "tasks",
                animation: 150,
                filter: '.add-task',
                handle: ".drag-handle"
            });
        }

    }

};

window.addEventListener("htmx:load", e => init(e.target));