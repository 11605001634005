
function renderEmailStats(content) {
    const charts = content.querySelectorAll("[data-email-campaign-stats]");

    if (charts.length > 0) {
        import("apexcharts").then(({ default: ApexCharts }) => {
            charts.forEach(el => {
                let options = {
                    series: JSON.parse(el.dataset.emailCampaignStatsSeries),
                    xaxis: {
                        type: "datetime",
                        categories: JSON.parse(el.dataset.emailCampaignStatsCategories),
                    },
                    chart: {
                        type: "bar",
                        height: 400
                    },
                    colors: ["#5fa2dd", "#9cc96b", "#ebaa4b", "#f76c51", "#986291", "#4ebcda"],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            endingShape: "rounded"
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        title: {
                            text: "# Emails"
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: (val) => (`${val} Email${val != 1 ? "s" : ""}`)
                        }
                    }
                };
                var chart = new ApexCharts(el, options);
                chart.render();
            });
        });
    }
}

window.addEventListener("htmx:load", e => {
    renderEmailStats(e.target);
});