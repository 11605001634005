function toggle(checkbox) {
    document.querySelectorAll(checkbox.dataset.checkAll).forEach(el => {
        el.checked = checkbox.checked;
    });
};

function init(content) {
    content.querySelectorAll("[data-check-all]").forEach(checkbox => {
        checkbox.addEventListener("click", function () {
            toggle(checkbox);
        });
    });
};

window.addEventListener("htmx:load", e => init(e.target));