var loginModal = $('#login-modal');
loginModal.modal({ backdrop: 'static', keyboard: false, show: false });
var countdownTimer = $("#countdown-timer");
var interval = null;

window.enableLockScreen = function enableLockScreen() {
    if (loginModal.hasClass('in')) loginModal.modal('hide');
    $('.login-error-msg').text("");
    clearInterval(interval);
    var seconds = 60;
    countdownTimer.text(seconds);
    var expireDate = new Date(readCookie('Ebisu.Sessiontime'));
    var currentDate = new Date();
    if (expireDate < currentDate) expireDate = new Date(currentDate.getTime() + 28 * 60000);
    var remainingTime = Math.floor((expireDate - currentDate) / 1000);
    remainingTime -= 90;
    $("#countdown-container").show();
    $("#login-container").hide();
    $("#two-factor-container").hide();
    interval = setInterval(function () {
        remainingTime -= 1;
        if (remainingTime < 0 && $("#two-factor-container").is(":hidden")) {
            var currentExpireDate = new Date(readCookie('Ebisu.Sessiontime'));
            if (currentExpireDate > expireDate) {
                clearInterval(interval);
                enableLockScreen();
            } else {
                if (!loginModal.hasClass('in')) loginModal.modal('show');
                countdownTimer.text(seconds);
                seconds -= 1;
                if (seconds < 0) {
                    $("#countdown-container").hide();
                    if ($("#two-factor-container").is(":hidden")) {
                        $("#login-container").show();
                    }
                }
            }
        }
    }, 1000);
}

$(enableLockScreen());
