
(function () {
    'use strict';

    if (document.querySelector('.list-filter') != null) {
        var inputs = document.querySelectorAll(".list-filter input[type='checkbox']:checked");
        var showClasses = [];
        var excludeClasses = [];

        for (var i = 0; i < inputs.length; i++) {
            var obj = { className: inputs[i].dataset.target, listName: inputs[i].dataset.list };
            showClasses.push(obj);
        }

        function filter(e) {
            var el = e.target;
            var targetClass = el.dataset.target;
            var listName = el.dataset.list;
            var obj = { className: targetClass, listName: listName };
            if (el.checked) {
                if (!showClasses.find(o => o.className === targetClass && o.listName === listName)) {
                    showClasses.push(obj);
                }

                excludeClasses = excludeClasses.filter(function (value, index, arr) {
                    return value.className != targetClass && value.listName != listName;
                });

                var itemsToAdd = document.querySelectorAll('.' + targetClass);

                for (var i = 0; i < itemsToAdd.length; i++) {
                    var okToAdd = true;
                    for (var j = 0; j < excludeClasses.length; j++) {
                        if (itemsToAdd[i].classList.contains(excludeClasses[j].className)) {
                            okToAdd = false
                        }
                    }
                    if (okToAdd) {
                        itemsToAdd[i].classList.add('in');
                    }
                }
            } else {
                showClasses = showClasses.filter(function (value, index, arr) {
                    return value.className != targetClass;
                });

                var listClasses = showClasses.filter(function (value, index, arr) {
                    return value.listName === listName;
                });

                if (!excludeClasses.find(o => o.className === targetClass && o.listName === listName)) {
                    excludeClasses.push(obj);
                }

                var itemsToRemove = document.querySelectorAll('.' + targetClass);

                for (var i = 0; i < itemsToRemove.length; i++) {
                    var okToRemove = true;
                    for (var j = 0; j < listClasses.length; j++) {
                        if (itemsToRemove[i].classList.contains(listClasses[j].className)) {
                            okToRemove = false;
                        }
                    }
                    if (okToRemove) {
                        itemsToRemove[i].classList.remove('in');
                    }
                }
            }
        }

        var listFilters = document.querySelectorAll('.list-filter');

        for (var i = 0; i < listFilters.length; i++) {
            listFilters[i].addEventListener('change', filter);
        }
    }

}());